import _ from 'underscore';

export default (error) => {
  let message = '';
  let errorsList = {};
  if (error.response) {
    if (error.response.data.message) {
      message = error.response.data.message;
    } else if (_.isObject(error.response.data)) {
      message = 'Bad Request';
      errorsList = error.response.data;
    } else {
      message = error.response.data;
    }
  } else {
    message = error.message;
  }
  return { error: true, message, errorsList };
};
