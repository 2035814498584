// Hooks
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useAuthStore from '../../store/useAuthStore';
import useGeneralStore from '../../store/useGeneralStore';

// Components
import { Navigate } from 'react-router-dom';

// Libraries
import { isExpired, decodeToken } from 'react-jwt';

const RequireAuth = ({ children }) => {
  let location = useLocation();
  const { user, token, resetAuthData } = useAuthStore();
  const { setSnackbarData } = useGeneralStore();

  useEffect(() => {
    validateAuth();
    // eslint-disable-next-line
  }, [location]);

  const validateAuth = () => {
    if (token) {
      if (!decodeToken(token) || isExpired(token)) {
        setSnackbarData({ open: true, severity: 'success', message: 'Logged Out Successfully' });
        resetAuthData();
      }
    }
  };

  if (!user || !token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default RequireAuth;
