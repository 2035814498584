// Components
import { Grid } from '@mui/material';
import Title from '../../components/shared/Title';
import FormComponent from '../../components/shared/FormComponent';

// Services
import { createBlog, getBlogById, updateBlog } from '../../services/blog';

const BlogForm = () => {
  const fields = [
    { name: 'Title', label: 'Title', type: 'string', required: true },
    { name: 'Category', label: 'Category', type: 'string', required: true },
    { name: 'ShortDescription', label: 'Short Description', type: 'text', required: true, maxLength: 110 },
    { name: 'Image', label: 'Image', type: 'img', required: true, preview: 'ImageUrl' },
    { name: 'IsFeatured', label: 'Is Featured', type: 'boolean', required: true },
    { name: 'Description', label: 'Description', type: 'editor', required: true },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Title text1="Blog" text2="Form" />
      </Grid>
      <Grid item xs={12}>
        <FormComponent fields={fields} getById={getBlogById} createElement={createBlog} updateElement={updateBlog} cacheName="blog" />
      </Grid>
    </Grid>
  );
};

export default BlogForm;
