// Components
import { Grid } from '@mui/material';
import Title from '../components/shared/Title';
import FormComponent from '../components/shared/FormComponent';

// Services
import { createUsers, getUsersById, updateUsers } from '../services/user';

const Profile = () => {
  const fields = [
    { name: 'Position', label: 'Position', type: 'string', required: true, disabled: true },
    { name: 'Name', label: 'Name', type: 'string', required: true },
    { name: 'Email', label: 'Email', type: 'string', required: true },
    { name: 'Password', label: 'Password', type: 'password', required: true, helperText: 'Must have at least 6 characters' },
    { name: 'LinkedIn', label: 'LinkedIn', type: 'string', required: true },
    { name: 'Photo', label: 'Photo', type: 'img', required: true, preview: 'PhotoUrl' },
    { name: 'Phone', label: 'Phone', type: 'string', required: false },
    { name: 'Country', label: 'Country', type: 'string', required: false },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Title text1="User" text2="Profile" />
      </Grid>
      <Grid item xs={12}>
        <FormComponent fields={fields} getById={getUsersById} createElement={createUsers} updateElement={updateUsers} cacheName="users" />
      </Grid>
    </Grid>
  );
};

export default Profile;
