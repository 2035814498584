// Components
import { Grid } from '@mui/material';
import Title from '../../components/shared/Title';
import FormComponent from '../../components/shared/FormComponent';

// Services
import { createCareers, getCareersById, updateCareers } from '../../services/careers';

const CareersForm = () => {
  const fields = [
    { name: 'Title', label: 'Title', type: 'string', required: true },
    { name: 'Department', label: 'Department', type: 'string', required: true },
    { name: 'Location', label: 'Location', type: 'string', required: true },
    { name: 'Description', label: 'Description', type: 'editor', required: true },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Title text1="Careers" text2="Form" />
      </Grid>
      <Grid item xs={12}>
        <FormComponent fields={fields} getById={getCareersById} createElement={createCareers} updateElement={updateCareers} cacheName="careers" />
      </Grid>
    </Grid>
  );
};

export default CareersForm;
