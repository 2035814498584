// Hooks
import useRouter from '../../hooks/useRouter';
import useTableControl from '../../hooks/useTableControl';
import useGetAllUsers from '../../hooks/useGetAllUsers';

// Components
import { Avatar, Box, Button, Grid, Typography } from '@mui/material';
import Title from '../../components/shared/Title';
import TableComponent from '../../components/shared/table/TableComponent';
import Filters from '../../components/shared/Filters';
import DeleteModal from '../../components/shared/DeleteModal';
import TestimonialDescription from '../../components/TestimonialDescription';

// Services
import { deleteTestimonials, getTestimonialsPaginated } from '../../services/testimonials';

// Libraries
import moment from 'moment';

const TestimonialsList = () => {
  const { data } = useGetAllUsers();
  const cacheName = 'testimonials';
  const {
    resetFilters,
    getDataWithFilters,
    valuesFilters,
    setValuesFilters,
    deleteData,
    showFilters,
    toggleFilters,
    handleDeleteItem,
    handleEditItem,
    handleCloseDeleteItem,
    ...tableControlProps
  } = useTableControl({
    getAllAnyWithFilters: getTestimonialsPaginated,
    orderBy: 'CreatedAt',
    cacheName,
  });
  const { navigate } = useRouter();

  const renderDate = (date) => {
    if (!date || date === null) {
      return 'N/A';
    }
    return moment(date).format('DD/MM/YYYY');
  };

  const renderUserName = (User) => {
    if (User) {
      return (
        <Box className="flex items-center">
          <Avatar src={User.PhotoUrl} alt="avatar" className="mr-2" />
          <Typography>{User.Name}</Typography>
        </Box>
      );
    }
    return null;
  };

  const renderDescription = (description) => {
    if (description) {
      return <TestimonialDescription description={description} />;
    }
    return null;
  };

  const columns = [
    { id: 'User', type: 'custom', label: 'User', render: renderUserName, isSortable: false },
    { id: 'Description', type: 'custom', label: 'Description', render: renderDescription, isSortable: false },
    { id: 'CreatedAt', type: 'custom', render: renderDate, label: 'Created At', isSortable: true },
    { id: 'UpdatedAt', type: 'custom', render: renderDate, label: 'Updated At', isSortable: true },
    { id: 'actions', type: 'actions' },
  ];

  const actions = [
    { type: 'edit', func: handleEditItem },
    { type: 'delete', func: handleDeleteItem, paramNeeded: 'all' },
  ];

  const filters = [
    { title: 'Description', name: 'Description', type: 'string', evaluator: 'lk', containerName: 'default' },
    {
      title: 'User',
      name: 'UserId',
      type: 'autocomplete',
      evaluator: 'eq',
      containerName: 'default',
      data: {
        options: data && data.data ? data.data : [],
        isObject: true,
        keyLabel: 'Name',
        keyValue: 'Id',
      },
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Title text1="Testimonials" text2="List" />
      </Grid>
      <Grid item xs={12} className="mb-5">
        <Button variant="contained" color="secondary" className="mr-3" size="small" onClick={() => navigate('add')}>
          Add new item
        </Button>
        <Button variant="outlined" color="primary" onClick={toggleFilters} size="small">
          {showFilters ? 'Hide' : 'Show'} filters
        </Button>
      </Grid>
      {showFilters && (
        <Grid item xs={12} md={3} className="md:pr-5 lg:mb-0 mb-5">
          <Filters
            filters={filters}
            resetFilters={resetFilters}
            sendFilters={getDataWithFilters}
            values={valuesFilters}
            setValues={setValuesFilters}
          />
        </Grid>
      )}
      <Grid item xs={12} md={showFilters ? 9 : 12}>
        <TableComponent {...tableControlProps} columns={columns} actions={actions} />
        <DeleteModal
          {...deleteData}
          handleCloseDeleteItem={handleCloseDeleteItem}
          deleteFunc={deleteTestimonials}
          resetFilters={resetFilters}
          titleIsObject={true}
          keyTitle={`${deleteData.element && deleteData.element.User ? 'User.Name' : ''}`}
          cacheName={cacheName}
        />
      </Grid>
    </Grid>
  );
};

export default TestimonialsList;
