import React from 'react';

// Components
import { Typography } from '@mui/material';

const Title = ({ text1, text2, colorForText = 1, isLight = false }) => {
  return (
    <Typography component="h4" className={`text-center mb-5 font-thin text-4xl relative ${isLight ? 'text-white-50' : ''}`}>
      {text1 && (colorForText === 1 ? <span className={`font-bold ${isLight ? '' : 'text-blue-700'}`}>{text1}</span> : text1)}{' '}
      {text2 && (colorForText === 2 ? <span className={`font-bold ${isLight ? '' : 'text-blue-700'}`}>{text2}</span> : text2)}
    </Typography>
  );
};

export default Title;
