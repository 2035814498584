import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useTableControlStore = create(
  persist(
    (set) => ({
      rowsPerPage: 20,
      showFilters: true,
      setRowsPerPage: (newRows) => set((state) => ({ ...state, rowsPerPage: newRows })),
      toggleFilters: () => set((state) => ({ ...state, showFilters: !state.showFilters })),
    }),
    {
      name: 'table-control',
    }
  )
);

export default useTableControlStore;
