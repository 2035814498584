// Components
import Home from '../pages/Home';
import CareersList from '../pages/careers/CareersList';
import CareersForm from '../pages/careers/CareersForm';
import BlogList from '../pages/blog/BlogList';
import BlogForm from '../pages/blog/BlogForm';
import UsersList from '../pages/users/UsersList';
import UsersForm from '../pages/users/UsersForm';
import Profile from '../pages/Profile';
import TestimonialsList from '../pages/testimonials/TestimonialsList';
import TestimonialsForm from '../pages/testimonials/TestimonialsForm';
import MetricsList from '../pages/metrics/MetricsList';
import MetricsForm from '../pages/metrics/MetricsForm';
import ClientsList from '../pages/clients/ClientsList';
import ClientForm from '../pages/clients/ClientForm';

import ServicesList from '../pages/services/ServicesList';
import ServiceForm from '../pages/services/ServiceForm';

// Icons
import HomeIcon from '@mui/icons-material/Home';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import GroupIcon from '@mui/icons-material/Group';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import ScoreIcon from '@mui/icons-material/Score';
import BusinessIcon from '@mui/icons-material/Business';
import SourceIcon from '@mui/icons-material/Source';

const routes = [
  {
    name: 'Home',
    path: '/',
    Component: Home,
    Icon: HomeIcon,
    permissionType: 'user',
    inSidebar: true,
    index: true,
  },
  {
    name: 'Profile',
    path: '/profile',
    Component: Profile,
    permissionType: 'user',
    inSidebar: false,
  },
  {
    name: 'Careers',
    path: '/careers',
    Icon: FolderSharedIcon,
    Component: CareersList,
    permissionType: 'editor',
    inSidebar: true,
    children: [
      {
        name: 'Careers Form Add',
        path: '/careers/add',
        Component: CareersForm,
        permissionType: 'editor',
        inSidebar: false,
      },
      {
        name: 'Careers Form Edit',
        path: '/careers/edit/:id',
        Component: CareersForm,
        permissionType: 'editor',
        inSidebar: false,
      },
    ],
  },
  {
    name: 'Blog',
    path: '/blog',
    Icon: NewspaperIcon,
    Component: BlogList,
    permissionType: 'editor',
    inSidebar: true,
    children: [
      {
        name: 'Blog Form Add',
        path: '/blog/add',
        Component: BlogForm,
        permissionType: 'editor',
        inSidebar: false,
      },
      {
        name: 'Blog Form Edit',
        path: '/blog/edit/:id',
        Component: BlogForm,
        permissionType: 'editor',
        inSidebar: false,
      },
    ],
  },
  {
    name: 'Users',
    path: '/users',
    Icon: GroupIcon,
    Component: UsersList,
    permissionType: 'admin',
    inSidebar: true,
    children: [
      {
        name: 'Users Form Add',
        path: '/users/add',
        Component: UsersForm,
        permissionType: 'admin',
        inSidebar: false,
      },
      {
        name: 'Users Form Edit',
        path: '/users/edit/:id',
        Component: UsersForm,
        permissionType: 'admin',
        inSidebar: false,
      },
    ],
  },
  {
    name: 'Testimonials',
    path: '/testimonials',
    Icon: FormatQuoteIcon,
    Component: TestimonialsList,
    permissionType: 'editor',
    inSidebar: true,
    children: [
      {
        name: 'Testimonials Form Add',
        path: '/testimonials/add',
        Component: TestimonialsForm,
        permissionType: 'editor',
        inSidebar: false,
      },
      {
        name: 'Testimonials Form Edit',
        path: '/testimonials/edit/:id',
        Component: TestimonialsForm,
        permissionType: 'editor',
        inSidebar: false,
      },
    ],
  },
  {
    name: 'Metrics',
    path: '/metrics',
    Icon: ScoreIcon,
    Component: MetricsList,
    permissionType: 'editor',
    inSidebar: true,
    children: [
      {
        name: 'Metrics Form Add',
        path: '/metrics/add',
        Component: MetricsForm,
        permissionType: 'editor',
        inSidebar: false,
      },
      {
        name: 'Metrics Form Edit',
        path: '/metrics/edit/:id',
        Component: MetricsForm,
        permissionType: 'editor',
        inSidebar: false,
      },
    ],
  },
  {
    name: 'Clients',
    path: '/clients',
    Icon: BusinessIcon,
    Component: ClientsList,
    permissionType: 'editor',
    inSidebar: true,
    children: [
      {
        name: 'Clients Form Add',
        path: '/clients/add',
        Component: ClientForm,
        permissionType: 'editor',
        inSidebar: false,
      },
      {
        name: 'Clients Form Edit',
        path: '/clients/edit/:id',
        Component: ClientForm,
        permissionType: 'editor',
        inSidebar: false,
      },
    ],
  },
  {
    name: 'Services',
    path: '/services',
    Icon: SourceIcon,
    Component: ServicesList,
    permissionType: 'editor',
    inSidebar: true,
    children: [
      {
        name: 'Services Form Add',
        path: '/services/add',
        Component: ServiceForm,
        permissionType: 'editor',
        inSidebar: false,
      },
      {
        name: 'Services Form Edit',
        path: '/services/edit/:id',
        Component: ServiceForm,
        permissionType: 'editor',
        inSidebar: false,
      },
    ],
  },
];

export default routes;
