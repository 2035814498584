// Hooks
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// Components
import { Divider, Drawer, List } from '@mui/material';
import { DrawerHeader, drawerWidth } from './styledComponents';
import ListItemDrawer from './ListItemDrawer';

// Store
import useGeneralStore from '../../store/useGeneralStore';

// Config
import routes from '../../config/routes';

// Assets
import logo from '../../assets/img/full-logo.png';

const Sidebar = ({ window }) => {
  const { openDrawer, toggleDrawer } = useGeneralStore();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const container = window !== undefined ? () => window().document.body : undefined;
  const drawerProps = {
    open: openDrawer,
    sx: {
      width: drawerWidth,
      flexShrink: 0,
      '& .MuiDrawer-paper': {
        width: drawerWidth,
        boxSizing: 'border-box',
      },
    },
    anchor: 'left',
    PaperProps: {
      className: 'bg-blue-900 text-white-50',
    },
    onClose: toggleDrawer,
    container,
  };

  const drawer = (
    <>
      <DrawerHeader>
        <img alt="Factor K Software" src={logo} className="w-44" />
      </DrawerHeader>
      <Divider />
      <List>
        {routes.map((route) => (
          <ListItemDrawer {...route} key={route.name} />
        ))}
      </List>
    </>
  );

  if (isDesktop) {
    return (
      <Drawer {...drawerProps} variant="persistent">
        {drawer}
      </Drawer>
    );
  }

  return (
    <Drawer
      {...drawerProps}
      variant="temporary"
      ModalProps={{
        keepMounted: true,
      }}
    >
      {drawer}
    </Drawer>
  );
};

export default Sidebar;
