import { createTheme } from '@mui/material';

const themeOptions = {
  typography: {
    fontFamily: [
      'Graphie',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 14,
  },
  palette: {
    primary: {
      main: '#0487fa',
    },
    secondary: {
      main: '#094280',
    },
    warning: {
      main: '#ffc82c',
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        filledSuccess: {
          backgroundColor: '#2e7d32',
          color: '#fff',
        },
        filledError: {
          backgroundColor: '#d32f2f',
          color: '#fff',
        },
        filledWarning: {
          backgroundColor: '#ffc82c',
          color: '#000',
        },
        filledInfo: {
          backgroundColor: '#0b67c2',
          color: '#fff',
        },
        icon: ({ ownerState }) => ({
          ...(ownerState.severity === 'error' &&
            ownerState.variant === 'filled' && {
              color: '#fff !important',
            }),
          ...(ownerState.severity === 'warning' &&
            ownerState.variant === 'filled' && {
              color: '#000 !important',
            }),
          ...(ownerState.severity === 'info' &&
            ownerState.variant === 'filled' && {
              color: '#fff !important',
            }),
          ...(ownerState.severity === 'success' &&
            ownerState.variant === 'filled' && {
              color: '#fff !important',
            }),
        }),
      },
    },
  },
};

export default createTheme(themeOptions);
