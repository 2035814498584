import _ from 'underscore';
import axiosInstance from '../config/axiosInstance';
import errorHandler from '../utils/errorHandler';

export const getTestimonialsPaginated = async ({ filters, continuationToken, order, rowsPerPage }) => {
  try {
    const headers = { 'x-page-size': rowsPerPage, 'x-is-paginated': true };

    if (filters && !_.isEmpty(filters)) {
      headers['x-filters'] = JSON.stringify(filters);
    }

    if (continuationToken) {
      headers['x-continuation-token'] = continuationToken;
    }

    if (order && order.by) {
      headers['x-order'] = `${order.by} ${order.type}`;
    }

    const { data } = await axiosInstance().get('/testimonial', { headers });
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const getTestimonialsById = async (id) => {
  try {
    const { data } = await axiosInstance().get(`/testimonial/${id}`);
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const createTestimonials = async (values) => {
  try {
    const { data } = await axiosInstance().post(`/testimonial`, values);
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const updateTestimonials = async (values, id) => {
  try {
    const { data } = await axiosInstance().put(`/testimonial/${id}`, values);
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const deleteTestimonials = async (id) => {
  try {
    const { data } = await axiosInstance().delete(`/testimonial/${id}`);
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};
