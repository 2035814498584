// Hooks
import useRouter from '../../hooks/useRouter';
import useTableControl from '../../hooks/useTableControl';

// Components
import { Avatar, Button, Grid } from '@mui/material';
import Title from '../../components/shared/Title';
import TableComponent from '../../components/shared/table/TableComponent';
import Filters from '../../components/shared/Filters';
import DeleteModal from '../../components/shared/DeleteModal';

// Services
import { deleteUsers, getUsersPaginated } from '../../services/user';

const UsersList = () => {
  const cacheName = 'users';
  const {
    resetFilters,
    getDataWithFilters,
    valuesFilters,
    setValuesFilters,
    deleteData,
    showFilters,
    toggleFilters,
    handleDeleteItem,
    handleEditItem,
    handleCloseDeleteItem,
    ...tableControlProps
  } = useTableControl({
    getAllAnyWithFilters: getUsersPaginated,
    orderBy: 'Name',
    cacheName,
  });
  const { navigate } = useRouter();

  const renderAvatar = (photo) => <Avatar alt="photo" src={photo} />;

  const columns = [
    { id: 'PhotoUrl', type: 'custom', label: 'Photo', render: renderAvatar, isSortable: false },
    { id: 'Name', type: 'string', label: 'Name', isSortable: true },
    { id: 'Email', type: 'string', label: 'Email', isSortable: true },
    { id: 'Position', type: 'string', label: 'Position', isSortable: true },
    { id: 'TypePosition', type: 'string', label: 'Type Position', isSortable: true, capitalize: true },
    { id: 'Role', type: 'string', label: 'Role', isSortable: true, capitalize: true },
    { id: 'Country', type: 'string', label: 'Country', isSortable: true },
    { id: 'Phone', type: 'string', label: 'Phone', isSortable: true },
    { id: 'actions', type: 'actions' },
  ];

  const actions = [
    { type: 'edit', func: handleEditItem },
    { type: 'delete', func: handleDeleteItem, paramNeeded: 'all' },
  ];

  const filters = [
    { title: 'Name', name: 'Name', type: 'string', evaluator: 'lk', containerName: 'default' },
    { title: 'Email', name: 'Email', type: 'string', evaluator: 'lk', containerName: 'default' },
    { title: 'Position', name: 'Position', type: 'string', evaluator: 'lk', containerName: 'default' },
    { title: 'Country', name: 'Country', type: 'string', evaluator: 'lk', containerName: 'default' },
    { title: 'Phone', name: 'Phone', type: 'string', evaluator: 'lk', containerName: 'default' },
    {
      title: 'Type Position',
      name: 'TypePosition',
      type: 'select',
      evaluator: 'eq',
      containerName: 'default',
      data: {
        options: [
          { label: 'Sherpa', value: 'sherpa' },
          { label: 'Administrative', value: 'administrative' },
          { label: 'Advisor', value: 'advisor' },
        ],
        isObject: true,
        keyLabel: 'label',
        keyValue: 'value',
      },
    },
    {
      title: 'Role',
      name: 'Role',
      type: 'select',
      evaluator: 'eq',
      containerName: 'default',
      data: {
        options: [
          { label: 'User', value: 'user' },
          { label: 'Editor', value: 'editor' },
          { label: 'Admin', value: 'admin' },
        ],
        isObject: true,
        keyLabel: 'label',
        keyValue: 'value',
      },
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Title text1="Users" text2="List" />
      </Grid>
      <Grid item xs={12} className="mb-5">
        <Button variant="contained" color="secondary" className="mr-3" size="small" onClick={() => navigate('add')}>
          Add new item
        </Button>
        <Button variant="outlined" color="primary" onClick={toggleFilters} size="small">
          {showFilters ? 'Hide' : 'Show'} filters
        </Button>
      </Grid>
      {showFilters && (
        <Grid item xs={12} md={3} className="md:pr-5 lg:mb-0 mb-5">
          <Filters
            filters={filters}
            resetFilters={resetFilters}
            sendFilters={getDataWithFilters}
            values={valuesFilters}
            setValues={setValuesFilters}
          />
        </Grid>
      )}
      <Grid item xs={12} md={showFilters ? 9 : 12}>
        <TableComponent {...tableControlProps} columns={columns} actions={actions} />
        <DeleteModal
          {...deleteData}
          handleCloseDeleteItem={handleCloseDeleteItem}
          deleteFunc={deleteUsers}
          resetFilters={resetFilters}
          keyTitle="Name"
          cacheName={cacheName}
        />
      </Grid>
    </Grid>
  );
};

export default UsersList;
