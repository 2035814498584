import React from 'react';

// Components
import { Outlet } from 'react-router-dom';
import Header from '../shared/Header';
import Sidebar from '../shared/Sidebar';
import { Main } from '../shared/styledComponents';

// Store
import useGeneralStore from '../../store/useGeneralStore';

const Layout = () => {
  const { openDrawer } = useGeneralStore();
  return (
    <>
      <Header />
      <Sidebar />
      <Main open={openDrawer}>
        <Outlet />
      </Main>
    </>
  );
};

export default Layout;
