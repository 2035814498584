// Hooks
import { useState } from 'react';

// Components
import { Button, Popover, Typography } from '@mui/material';

const TestimonialDescription = ({ description }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'testimonial-description' : undefined;

  return (
    <div>
      <Button aria-describedby={id} variant="contained" onClick={handleClick} size="small">
        Read
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography className="p-2 w-128">{description}</Typography>
      </Popover>
    </div>
  );
};

export default TestimonialDescription;
