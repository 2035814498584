import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useAuthStore = create(
  persist(
    (set) => ({
      user: null,
      token: '',
      setAuthData: (data) => set((state) => ({ ...state, user: data.user, token: data.token })),
      resetAuthData: () => set((state) => ({ ...state, user: null, token: '' })),
    }),
    {
      name: 'auth',
    }
  )
);

export default useAuthStore;
