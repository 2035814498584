const generateFilter = (container, name, value, evaluator) => {
  let element = container ? `${container} and ` : '$filter=';
  switch (evaluator) {
    case 'eq':
      element += `${name} eq ${value}`;
      break;
    case 'lk':
      element += `substringof(${value}, ${name})`;
      break;
    default:
      element = container;
      break;
  }
  return element;
};

export default generateFilter;
