// Hooks
import { memo, useCallback } from 'react';
import useTableControlStore from '../../../store/useTableControlStore';

// Components
import { Box, Paper, Table, TableBody, TableContainer, TablePagination, Typography } from '@mui/material';
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableRow from './EnhancedTableRow';
import Loading from '../Loading';

const TableComponent = memo(({ query, columns, order, page, handleRequestSort, getDataNextPage, getDataRowsPerPage, actions, cacheName }) => {
  const { rowsPerPage } = useTableControlStore();

  const handleChangePage = useCallback(
    async (event, newPage) => {
      await getDataNextPage(newPage);
    },
    [getDataNextPage]
  );

  const handleChangeRowsPerPage = useCallback(
    async (event) => {
      const rowsPerPage = parseInt(event.target.value);
      await getDataRowsPerPage(rowsPerPage);
    },
    [getDataRowsPerPage]
  );

  const handleSortByColumn = useCallback(
    (event, property) => {
      handleRequestSort(event, property);
    },
    [handleRequestSort]
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%' }}>
        <TableContainer sx={{ height: rowsPerPage < 10 ? 'auto' : 650 }}>
          <Table stickyHeader aria-labelledby="table">
            <EnhancedTableHead order={order} columns={columns} onRequestSort={handleSortByColumn} />
            <TableBody>
              {query.isFetching ? (
                <tr>
                  <td colSpan={columns.length} className="p-4">
                    <Loading />
                  </td>
                </tr>
              ) : query.data && query.data.data && query.data.data.length > 0 ? (
                query.data.data.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return <EnhancedTableRow key={row.Id} columns={columns} row={row} labelId={labelId} actions={actions} cacheName={cacheName} />;
                })
              ) : (
                <tr>
                  <td colSpan={columns.length} className="p-4">
                    <Typography align="center" fontWeight={600} variant="h6">
                      Empty list
                    </Typography>
                  </td>
                </tr>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
          count={query.data?.count || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
});

export default TableComponent;
