import { create } from 'zustand';

const useGeneralStore = create((set) => ({
  snackbar: { open: false, severity: 'info', message: '' },
  openDrawer: true,
  setSnackbarData: (data) => set((state) => ({ ...state, snackbar: { ...state.snackbar, ...data } })),
  resetSnackbarData: () => set((state) => ({ ...state, snackbar: { open: false, severity: 'info', message: '' } })),
  toggleDrawer: () => set((state) => ({ ...state, openDrawer: !state.openDrawer })),
}));

export default useGeneralStore;
