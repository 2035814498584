import { Alert, Slide, Snackbar } from '@mui/material';
import React from 'react';
import useGeneralStore from '../../store/useGeneralStore';

const SnackbarAlert = () => {
  const {
    snackbar: { message, severity, open },
    setSnackbarData,
  } = useGeneralStore();

  const handleClose = () => {
    setSnackbarData({ open: false, severity: 'info', message: '' });
  };

  if (!open) {
    return null;
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      TransitionComponent={(props) => <Slide {...props} direction="down" />}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }} variant="filled">
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarAlert;
