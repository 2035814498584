// Hooks
import { useRef } from 'react';

// Components
import { Box, Button, FormHelperText, Typography } from '@mui/material';

const ImgField = ({ label, values, preview, name, onChangeImg, disabled, helperText, errors, setValues }) => {
  const fieldImage = useRef(null);

  const onCancelUpload = (name) => {
    fieldImage.current.value = '';
    setValues((prev) => ({ ...prev, [name]: undefined }));
  };

  return (
    <>
      <Typography className="text-gray-500">{label}</Typography>
      {(values[preview] || values[name]) && (
        <Box className="mb-3">
          <div
            style={{ backgroundImage: `url(${values[`${name}_preview`] || values[preview]})` }}
            className="md:w-128 h-60 bg-contain bg-no-repeat"
          />
          {values[name] && <Typography className="text-sm italic">{values[name].name}</Typography>}
        </Box>
      )}
      <input
        accept="image/*"
        className="hidden"
        id={`${name}-img-input`}
        disabled={disabled}
        type="file"
        name={name}
        onChange={onChangeImg}
        ref={fieldImage}
      />
      <Box className="flex items-center justify-start">
        {values[name] && (
          <Button variant="outlined" color="warning" className="mr-2" onClick={() => onCancelUpload(name)}>
            Cancel upload
          </Button>
        )}
        <label htmlFor={`${name}-img-input`}>
          <Button variant="contained" component="span" color="secondary" disabled={disabled}>
            Upload
          </Button>
        </label>
      </Box>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormHelperText className="text-danger-600">{errors[name]}</FormHelperText>
    </>
  );
};

export default ImgField;
