// Hooks
import useRouter from '../../hooks/useRouter';
import useTableControl from '../../hooks/useTableControl';

// Components
import { Button, Grid } from '@mui/material';
import Title from '../../components/shared/Title';
import TableComponent from '../../components/shared/table/TableComponent';
import Filters from '../../components/shared/Filters';
import DeleteModal from '../../components/shared/DeleteModal';

// Services
import { deleteCareers, getCareersPaginated } from '../../services/careers';

// Libraries
import moment from 'moment';

const CareersList = () => {
  const cacheName = 'careers';
  const {
    resetFilters,
    getDataWithFilters,
    valuesFilters,
    setValuesFilters,
    deleteData,
    showFilters,
    toggleFilters,
    handleDeleteItem,
    handleEditItem,
    handleCloseDeleteItem,
    ...tableControlProps
  } = useTableControl({
    getAllAnyWithFilters: getCareersPaginated,
    orderBy: 'Title',
    cacheName,
  });
  const { navigate } = useRouter();

  const renderDate = (date) => {
    if (!date || date === null) {
      return 'N/A';
    }
    return moment(date).format('DD/MM/YYYY');
  };

  const columns = [
    { id: 'Title', type: 'string', label: 'Title', isSortable: true },
    { id: 'Department', type: 'string', label: 'Department', isSortable: true },
    { id: 'Location', type: 'string', label: 'Location', isSortable: true },
    { id: 'CreatedAt', type: 'custom', render: renderDate, label: 'Created At', isSortable: true },
    { id: 'UpdatedAt', type: 'custom', render: renderDate, label: 'Updated At', isSortable: true },
    { id: 'actions', type: 'actions' },
  ];

  const actions = [
    { type: 'edit', func: handleEditItem },
    { type: 'delete', func: handleDeleteItem, paramNeeded: 'all' },
  ];

  const filters = [
    { title: 'Title', name: 'Title', type: 'string', evaluator: 'lk', containerName: 'default' },
    {
      title: 'Department',
      name: 'Department',
      type: 'string',
      evaluator: 'lk',
      containerName: 'default',
    },
    { title: 'Location', name: 'Location', type: 'string', evaluator: 'lk', containerName: 'default' },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Title text1="Careers" text2="List" />
      </Grid>
      <Grid item xs={12} className="mb-5">
        <Button variant="contained" color="secondary" className="mr-3" size="small" onClick={() => navigate('add')}>
          Add new item
        </Button>
        <Button variant="outlined" color="primary" onClick={toggleFilters} size="small">
          {showFilters ? 'Hide' : 'Show'} filters
        </Button>
      </Grid>
      {showFilters && (
        <Grid item xs={12} md={3} className="md:pr-5 lg:mb-0 mb-5">
          <Filters
            filters={filters}
            resetFilters={resetFilters}
            sendFilters={getDataWithFilters}
            values={valuesFilters}
            setValues={setValuesFilters}
          />
        </Grid>
      )}
      <Grid item xs={12} md={showFilters ? 9 : 12}>
        <TableComponent {...tableControlProps} columns={columns} actions={actions} />
        <DeleteModal
          {...deleteData}
          handleCloseDeleteItem={handleCloseDeleteItem}
          deleteFunc={deleteCareers}
          resetFilters={resetFilters}
          cacheName={cacheName}
        />
      </Grid>
    </Grid>
  );
};

export default CareersList;
