// Components
import { Grid } from '@mui/material';
import Title from '../../components/shared/Title';
import FormComponent from '../../components/shared/FormComponent';

// Services
import { createClient, getClientById, updateClient } from '../../services/client';

const ClientForm = () => {
  const fields = [
    { name: 'Logo', label: 'Logo', type: 'img', required: true, preview: 'LogoUrl' },
    { name: 'Name', label: 'Name', type: 'string', required: true },
    { name: 'Order', label: 'Order', type: 'number', required: true },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Title text1="Client" text2="Form" />
      </Grid>
      <Grid item xs={12}>
        <FormComponent fields={fields} getById={getClientById} createElement={createClient} updateElement={updateClient} cacheName="client" />
      </Grid>
    </Grid>
  );
};

export default ClientForm;
