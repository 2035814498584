import { CircularProgress, Typography } from '@mui/material';

const Loading = ({ isLight = false, isHorizontal = true }) => {
  return (
    <div className={`${isHorizontal ? 'flex' : ''} items-center justify-center text-center`}>
      <CircularProgress color={isLight ? 'primary' : 'secondary'} size={30} />
      <Typography className="ml-3">Loading...</Typography>
    </div>
  );
};

export default Loading;
