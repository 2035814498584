// Hooks
import { useState } from 'react';
import useGeneralStore from '../store/useGeneralStore';
import useAuthStore from '../store/useAuthStore';

// Components
import { LoadingButton } from '@mui/lab';
import { Box, FormGroup, Grid, TextField, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Title from '../components/shared/Title';

// Services
import { login } from '../services/user';

// Assets
import logo from '../assets/img/full-logo.png';

// Libraries
import moment from 'moment/moment';

const Login = () => {
  const [data, setData] = useState({ Email: '', Password: '' });
  const [isLoading, setIsLoading] = useState(false);
  const { setSnackbarData, resetSnackbarData } = useGeneralStore();
  const { setAuthData } = useAuthStore();
  const onChangeData = ({ target: { value, name } }) => {
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    resetSnackbarData();
    const result = await login(data);
    if (result.error) {
      setSnackbarData({ open: true, message: result.message, severity: 'error' });
    } else {
      setAuthData({ user: result.data, token: result.Token });
      setSnackbarData({ open: true, message: 'Logged In Successfully', severity: 'success' });
    }
    setIsLoading(false);
  };

  return (
    <Grid container spacing={4} alignItems="center">
      <Grid item xs={12} md={7}>
        <Box sx={{ backgroundColor: 'secondary.main' }} className="md:h-screen h-28 flex items-center justify-center">
          <img src={logo} alt="logo" className="w-9/12" />
        </Box>
      </Grid>
      <Grid item xs={12} md={5}>
        <form className="lg:p-32 p-10" onSubmit={handleLogin}>
          <Title text1="Sherpa" text2="Login" />
          <FormGroup className="mb-5">
            <TextField label="Email" variant="outlined" required value={data.Email} onChange={onChangeData} name="Email" />
          </FormGroup>
          <FormGroup className="mb-5">
            <TextField label="Password" variant="outlined" required type="password" value={data.Password} onChange={onChangeData} name="Password" />
          </FormGroup>
          <div className="mb-5 text-right">
            <Link to="/request-password" className="hover:underline hover:text-blue-700 text-blue-900 inline-block">
              Forgot password?
            </Link>
          </div>
          <FormGroup>
            <LoadingButton variant="contained" type="submit" loading={isLoading}>
              Login
            </LoadingButton>
          </FormGroup>
        </form>
        <Typography className="text-center">© {moment().year()} All Rights Reserved</Typography>
      </Grid>
    </Grid>
  );
};

export default Login;
