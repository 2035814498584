// Hooks
import { useQuery } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import useTableControlStore from '../store/useTableControlStore';
import useRouter from './useRouter';

const useTableControl = ({ orderBy = 'Id', getAllAnyWithFilters, initFilters = '', isEnabled = true, cacheName }) => {
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState(initFilters);
  const [continuationToken, setContinuationToken] = useState('');
  const [order, setOrder] = useState({ type: 'asc', by: orderBy });
  const [valuesFilters, setValuesFilters] = useState({});
  const [deleteData, setDeleteData] = useState({ open: false, element: null });
  const { rowsPerPage, setRowsPerPage, showFilters, toggleFilters } = useTableControlStore();
  const { navigate } = useRouter();

  const query = useQuery(
    [cacheName, { filters, page, rowsPerPage, order }],
    () => getAllAnyWithFilters({ filters, continuationToken, rowsPerPage, order }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 1000 * 60 * 60,
      enabled: isEnabled,
      keepPreviousData: true,
    }
  );

  const handleRequestSort = async (event, property) => {
    setContinuationToken('');
    const isAsc = order.by === property && order.type === 'asc';
    const newOrder = { type: isAsc ? 'desc' : 'asc', by: property };
    setPage(0);
    setOrder(newOrder);
  };

  const resetFilters = async () => {
    await getDataWithFilters('');
  };

  const getDataNextPage = async (newPage) => {
    setContinuationToken(query.data.continuationToken);
    setPage(newPage);
  };

  const getDataRowsPerPage = async (rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    setContinuationToken('');
    setPage(0);
  };

  const getDataWithFilters = async (filters) => {
    setContinuationToken('');
    setFilters(filters);
    setPage(0);
  };

  const handleDeleteItem = useCallback((element) => {
    setDeleteData({ open: true, element });
  }, []);

  const handleCloseDeleteItem = useCallback(() => {
    setDeleteData({ open: false, element: null });
  }, []);

  const handleEditItem = useCallback(
    (id) => {
      navigate(`edit/${id}`);
    },
    [navigate]
  );

  return {
    query,
    page,
    order,
    cacheName,
    valuesFilters,
    showFilters,
    toggleFilters,
    setValuesFilters,
    handleRequestSort,
    getDataNextPage,
    getDataRowsPerPage,
    getDataWithFilters,
    resetFilters,
    handleDeleteItem,
    handleCloseDeleteItem,
    deleteData,
    handleEditItem,
  };
};

export default useTableControl;
