export const propertyAccessor = (obj, string) => {
  const parts = string.split('.');
  const newObj = obj[parts[0]];
  if (parts[1]) {
    parts.splice(0, 1);
    const newString = parts.join('.');
    return propertyAccessor(newObj, newString);
  }
  return newObj;
};

export const validateNumber = (value) => {
  const regex = /^[0-9]*$/;
  if (value === '' || regex.test(value)) {
    return true;
  } else {
    return false;
  }
};
