// Components
import { Avatar, Button, Grid, Paper, Typography } from '@mui/material';
import Title from '../components/shared/Title';
import useAuthStore from '../store/useAuthStore';

const Home = () => {
  const { user } = useAuthStore();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Title text1="Home" text2="Factor K" />
      </Grid>

      <Grid item xs={12} md={6} component={Paper} className="p-5">
        <Grid container>
          <Grid item md={5} className="flex items-center justify-center">
            <Avatar src={user.PhotoUrl} sx={{ width: 120, height: 120 }} alt="profile" className="ring-2 ring-blue-700 border-dashed ring-offset-4" />
          </Grid>
          <Grid item md={7}>
            <Typography variant="h4" className="text-gray-800 leading-none">
              {user.Name}
            </Typography>
            <a href={`mailto:${user.Email}`} target="_blank" rel="noreferrer">
              <Typography className="text-sm text-blue-600 mb-3 hover:text-blue-900 inline-block">{user.Email}</Typography>
            </a>
            <Typography>{user.Position}</Typography>
            <a href={user.LinkedIn} target="_blank" rel="noreferrer">
              <Button variant="contained" size="small" className="mt-3">
                LinkedIn
              </Button>
            </a>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Home;
