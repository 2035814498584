// Components
import { Navigate, Route, Routes } from 'react-router-dom';
import SnackbarAlert from '../shared/SnackbarAlert';
import Layout from './Layout';
import PublicRoute from './PublicRoute';
import RequireAuth from './RequireAuth';

// Store
import useAuthStore from '../../store/useAuthStore';

// Pages
import Login from '../../pages/Login';
import RequestPassword from '../../pages/password/RequestPassword';
import ResetPassword from '../../pages/password/ResetPassword';

// Config
import routes from '../../config/routes';

const Router = () => {
  const { user } = useAuthStore();

  const flatRoutes = (routes) => {
    let newRoutes = [];
    routes.forEach((route) => {
      newRoutes.push(route);
      if (route.children && route.children.length > 0) {
        newRoutes.push(...flatRoutes(route.children));
      }
    });
    return newRoutes;
  };

  const renderRoute = ({ path, Component, permissionType, index = false }) => {
    if (user && user.Role) {
      if ((permissionType === 'admin' || permissionType === 'editor') && user.Role === 'user') {
        return null;
      }

      if (permissionType === 'admin' && user.Role === 'editor') {
        return null;
      }
      return <Route exact path={path} element={<Component />} key={path} index={index} />;
    }
    return null;
  };

  const renderRoutes = (routes) => {
    const flattedRoutes = flatRoutes(routes);
    return flattedRoutes.map((route) => renderRoute(route));
  };

  return (
    <>
      <SnackbarAlert />
      <Routes>
        <Route
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/request-password"
          element={
            <PublicRoute>
              <RequestPassword />
            </PublicRoute>
          }
        />
        <Route
          path="/reset-password"
          element={
            <PublicRoute>
              <ResetPassword />
            </PublicRoute>
          }
        />
        <Route
          path="/"
          element={
            <RequireAuth>
              <Layout />
            </RequireAuth>
          }
        >
          {renderRoutes(routes)}
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </>
  );
};

export default Router;
