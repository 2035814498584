// Hooks
import { memo, useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

// Components
import { Box, IconButton, TableCell, TableRow } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

// Libraries
import moment from 'moment/moment';

const EnhancedTableRow = memo(({ cacheName, columns, row, labelId, actions }) => {
  const queryClient = useQueryClient();

  const getValueRenderCustom = useCallback((value, row) => {
    return value === 'all' ? row : row[value];
  }, []);

  const returnParamNeeded = useCallback(
    (param) => {
      if (param === 'all') {
        return row;
      }
      return row[param] || row['Id'];
    },
    [row]
  );

  const renderActions = useCallback(() => {
    return (
      <Box className="flex items-center">
        {actions.map((action) => {
          switch (action.type) {
            case 'edit':
              return (
                <IconButton key={action.type} onClick={() => action.func(returnParamNeeded(action.paramNeeded))} color="warning">
                  <EditIcon />
                </IconButton>
              );
            case 'delete':
              return (
                <IconButton key={action.type} onClick={() => action.func(returnParamNeeded(action.paramNeeded))} color="error">
                  <DeleteIcon />
                </IconButton>
              );
            default:
              return null;
          }
        })}
      </Box>
    );
  }, [actions, returnParamNeeded]);

  const renderValue = useCallback(
    (row, col) => {
      switch (col.type) {
        case 'custom':
          if (col.render) {
            return col.render(getValueRenderCustom(col.valueForRender || col.id, row));
          }
          return null;
        case 'boolean':
          if (row[col.id] === true) {
            return 'Yes';
          } else {
            return 'No';
          }
        case 'actions':
          return actions ? renderActions() : null;
        case 'time':
          return moment(row[col.id], 'HH:mm:ss').format('hh:mm A');
        default:
          return row[col.id];
      }
    },
    [getValueRenderCustom, actions, renderActions]
  );

  const presetData = () => {
    queryClient.setQueryData([cacheName, row.Id], row);
  };

  return (
    <TableRow tabIndex={-1} onMouseEnter={presetData}>
      {columns.map((col) => {
        return (
          <TableCell
            key={`${col.id}-row`}
            id={labelId}
            scope="row"
            padding={col.disablePadding ? 'none' : 'normal'}
            className={`whitespace-nowrap ${col.capitalize ? 'capitalize' : ''}`}
          >
            {renderValue(row, col)}
          </TableCell>
        );
      })}
    </TableRow>
  );
});

export default EnhancedTableRow;
