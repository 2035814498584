import React, { useState } from 'react';

// Components
import { Avatar, Box, Button, IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar } from './styledComponents';

// Store
import useAuthStore from '../../store/useAuthStore';
import useGeneralStore from '../../store/useGeneralStore';
import useRouter from '../../hooks/useRouter';

const Header = () => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const { openDrawer, toggleDrawer, setSnackbarData } = useGeneralStore();
  const { user, resetAuthData } = useAuthStore();
  const { navigate } = useRouter();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    handleCloseUserMenu();
    resetAuthData();
    setSnackbarData({ open: true, severity: 'success', message: 'Logged Out Successfully' });
  };

  return (
    <AppBar position="fixed" open={openDrawer} color="inherit">
      <Toolbar>
        <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={toggleDrawer}>
          <MenuIcon />
        </IconButton>
        <Box sx={{ flexGrow: 1 }}>
          {!openDrawer && (
            <Typography variant="h6" component="div" className="flex items-center font-extrabold md:justify-start justify-center" color="secondary">
              Factor
              <img alt="factor k software" src={process.env.PUBLIC_URL + '/FactorK_mark_color.svg'} className="w-16" />
              Software
            </Typography>
          )}
        </Box>

        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Open settings">
            <Button variant="text" onClick={handleOpenUserMenu} sx={{ paddingY: 0 }} className="flex items-center normal-case" color="secondary">
              <Typography className="mr-2 md:block hidden">{user.Name}</Typography>
              <Avatar alt={user.Name} src={user.PhotoUrl} />
            </Button>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem onClick={() => navigate('/profile')}>
              <Typography textAlign="center">Profile</Typography>
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <Typography textAlign="center">Logout</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
