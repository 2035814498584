// Components
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { validateNumber } from '../../utils/general';

// Utils
import generateFilter from '../../utils/generateFilter';

const Filters = ({ filters, sendFilters, resetFilters, values, setValues }) => {
  const handleChangeString = (e) => {
    const { name, value, id } = e.target;
    if (value) {
      setValues({
        ...values,
        [name || id]: value,
      });
    } else {
      setValues((values) => {
        delete values[name || id];
        return {
          ...values,
        };
      });
    }
  };
  const handleChangeNumber = ({ target: { name, value, id } }) => {
    if (value) {
      if (validateNumber(value)) {
        setValues({
          ...values,
          [name || id]: value,
        });
      }
    } else {
      setValues((values) => {
        delete values[name || id];
        return {
          ...values,
        };
      });
    }
  };

  const handleChangeSelect = (e) => {
    const { name, value, id } = e.target;
    if (value && value !== '' && value !== -1) {
      setValues({
        ...values,
        [name || id]: value,
      });
    } else {
      setValues((values) => {
        delete values[name || id];
        return {
          ...values,
        };
      });
    }
  };

  const handleChangeAutocomplete = (newValue, name, keyValue) => {
    if (newValue && newValue !== '' && newValue !== -1) {
      setValues({
        ...values,
        [name]: keyValue ? (newValue ? newValue[keyValue] : '') : newValue,
      });
    } else {
      setValues((values) => {
        delete values[name];
        return {
          ...values,
        };
      });
    }
  };

  const renderFilter = (filter) => {
    switch (filter.type) {
      case 'string':
        return (
          <FormGroup className="mb-3" key={filter.name}>
            <TextField id={filter.name} name={filter.name} label={filter.title} value={values[filter.name] || ''} onChange={handleChangeString} />
          </FormGroup>
        );
      case 'number':
        return (
          <FormGroup className="mb-3" key={filter.name}>
            <TextField id={filter.name} name={filter.name} label={filter.title} value={values[filter.name] || ''} onChange={handleChangeNumber} />
          </FormGroup>
        );
      case 'boolean':
        return (
          <FormGroup className="mb-3" key={filter.name}>
            <FormControl>
              <InputLabel id={`${filter.name}-select-label`}>{filter.title}</InputLabel>
              {
                <Select
                  labelId={`${filter.name}-select-label`}
                  id={`${filter.name}-select`}
                  name={filter.name}
                  label={filter.title}
                  value={values[filter.name] || ''}
                  onChange={handleChangeSelect}
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              }
            </FormControl>
          </FormGroup>
        );
      case 'select':
        if (filter.data) {
          const { keyLabel, keyValue, options, isObject } = filter.data;
          return (
            <FormGroup className="mb-3" key={filter.name}>
              <FormControl>
                <InputLabel id={`${filter.name}-select-label`}>{filter.title}</InputLabel>
                {
                  <Select
                    labelId={`${filter.name}-select-label`}
                    id={`${filter.name}-select`}
                    name={filter.name}
                    label={filter.title}
                    value={values[filter.name] || ''}
                    onChange={handleChangeSelect}
                  >
                    {options && options.length > 0 ? (
                      options.map((option) => (
                        <MenuItem key={isObject ? option[keyValue] : option} value={isObject ? option[keyValue] : option}>
                          {isObject ? option[keyLabel] : option}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem aria-label="None" value="">
                        <Typography variant="inherit" component="span" className="text-gray-500">
                          No hay opciones
                        </Typography>
                      </MenuItem>
                    )}
                  </Select>
                }
              </FormControl>
            </FormGroup>
          );
        }
        return null;

      case 'autocomplete': {
        if (filter.data) {
          const { options, isObject, keyValue, keyLabel } = filter.data;

          let value = '';
          if (isObject) {
            value = options.find((op) => op[keyValue] === values[filter.name]) || null;
          } else {
            value = values[filter.name];
          }

          return (
            <FormGroup className="mb-3" key={filter.name}>
              <Autocomplete
                id={`autocomplete_${filter.name}`}
                options={options}
                renderOption={(props, option) => (
                  <li {...props} key={isObject ? option[keyValue] : option}>
                    {isObject ? option[keyLabel] : option}
                  </li>
                )}
                getOptionLabel={(option) => {
                  if (option) {
                    return isObject ? option[keyLabel] : option;
                  }
                  return '';
                }}
                renderInput={(params) => <TextField {...params} label={filter.title} />}
                noOptionsText="Empty list"
                value={value}
                onChange={(e, value) => handleChangeAutocomplete(value, filter.name, keyValue)}
              />
            </FormGroup>
          );
        }
        return null;
      }
      default:
        return null;
    }
  };

  const getFilter = (key) => filters.find((filter) => filter.name === key);

  const makeFilter = (filter, value, container) => {
    switch (filter.type) {
      case 'string':
        return generateFilter(container, filter.name, `'${value}'`, filter.evaluator);
      case 'number':
        return generateFilter(container, filter.name, value, filter.evaluator);
      case 'boolean':
        return generateFilter(container, filter.name, value, filter.evaluator);
      case 'select':
        if (filter.data.isMultiple) {
          // let element = container ? `${container} and ` : '$filter=';
          // switch (filter.evaluator) {
          //     case 'eq':
          //         element += `${filter.name} eq '${value}'`;
          //         break;
          //     case 'lk':
          //         element += `substringof('${value}', ${filter.name})`;
          //         break;
          //     default:
          //         break;
          // }
          // return element;
        } else {
          return generateFilter(container, filter.name, `'${value}'`, filter.evaluator);
        }
        break;

      case 'autocomplete':
        // TODO: filter.data.isMultiple
        return generateFilter(container, filter.name, `'${value}'`, filter.evaluator);
      default:
        break;
    }
  };

  const handleResetFilters = () => {
    setValues({});
    resetFilters();
  };

  const handleSubmitFilters = () => {
    let filters = {};
    Object.keys(values).forEach((key) => {
      const filter = getFilter(key);
      if (filter) {
        filters[filter.containerName] = makeFilter(filter, values[key], filters[filter.containerName]);
      }
    });
    sendFilters(filters);
  };

  return (
    <Paper>
      <Typography color="secondary" component="h5" variant="h6" className="p-3 font-bold" textAlign="center">
        Filters
      </Typography>
      <Divider />
      <Box className="p-3">
        {filters.map((filter) => renderFilter(filter))}
        <FormGroup>
          <Button variant="contained" color="primary" size="small" onClick={handleSubmitFilters}>
            Send filters
          </Button>

          <Button className="mt-2" variant="contained" color="warning" size="small" onClick={handleResetFilters}>
            Clear all
          </Button>
        </FormGroup>
      </Box>
    </Paper>
  );
};

export default Filters;
