import _ from 'underscore';
import axiosInstance from '../config/axiosInstance';
import errorHandler from '../utils/errorHandler';

export const getClientPaginated = async ({ filters, continuationToken, order, rowsPerPage }) => {
  try {
    const headers = { 'x-page-size': rowsPerPage, 'x-is-paginated': true };

    if (filters && !_.isEmpty(filters)) {
      headers['x-filters'] = JSON.stringify(filters);
    }

    if (continuationToken) {
      headers['x-continuation-token'] = continuationToken;
    }

    if (order && order.by) {
      headers['x-order'] = `${order.by} ${order.type}`;
    }

    const { data } = await axiosInstance().get('/client', { headers });
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const getClientById = async (id) => {
  try {
    const { data } = await axiosInstance().get(`/client/${id}`);
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const createClient = async (values) => {
  try {
    const { data } = await axiosInstance().post(`/client`, values, { headers: { 'Content-Type': 'multipart/form-data' } });
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const updateClient = async (values, id) => {
  try {
    const { data } = await axiosInstance().put(`/client/${id}`, values, { headers: { 'Content-Type': 'multipart/form-data' } });
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const deleteClient = async (id) => {
  try {
    const { data } = await axiosInstance().delete(`/client/${id}`);
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};
