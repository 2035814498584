// Hooks
import { useState } from 'react';
import useGeneralStore from '../../store/useGeneralStore';

// Components
import { LoadingButton } from '@mui/lab';
import { Box, FormGroup, Grid, TextField, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Title from '../../components/shared/Title';

// Services
import { requestPassword } from '../../services/user';

// Assets
import logo from '../../assets/img/full-logo.png';

// Libraries
import moment from 'moment/moment';

const RequestPassword = () => {
  const [Email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { setSnackbarData, resetSnackbarData } = useGeneralStore();

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    resetSnackbarData();
    const result = await requestPassword({ Email });
    if (result.error) {
      setSnackbarData({ open: true, message: result.message, severity: 'error' });
    } else {
      setEmail('');
      setSnackbarData({ open: true, message: result.message, severity: 'success' });
    }
    setIsLoading(false);
  };

  return (
    <Grid container spacing={4} alignItems="center">
      <Grid item xs={12} md={7}>
        <Box sx={{ backgroundColor: 'secondary.main' }} className="md:h-screen h-28 flex items-center justify-center">
          <img src={logo} alt="logo" className="w-9/12" />
        </Box>
      </Grid>
      <Grid item xs={12} md={5}>
        <form className="lg:p-32 p-10" onSubmit={handleLogin}>
          <Title text1="Request" text2="Password" />
          <FormGroup className="mb-5">
            <TextField label="Email" variant="outlined" required value={Email} onChange={({ target: { value } }) => setEmail(value)} />
          </FormGroup>
          <div className="flex items-center justify-between">
            <LoadingButton variant="contained" type="submit" loading={isLoading}>
              Send
            </LoadingButton>

            <Link to="/login" className="hover:underline hover:text-blue-700 text-blue-900 inline-block">
              Back to login
            </Link>
          </div>
        </form>
        <Typography className="text-center">© {moment().year()} All Rights Reserved</Typography>
      </Grid>
    </Grid>
  );
};

export default RequestPassword;
