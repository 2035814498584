// Hooks
import useRouter from '../../hooks/useRouter';
import useTableControl from '../../hooks/useTableControl';

// Components
import { Avatar, Button, Grid } from '@mui/material';
import Title from '../../components/shared/Title';
import TableComponent from '../../components/shared/table/TableComponent';
import Filters from '../../components/shared/Filters';
import DeleteModal from '../../components/shared/DeleteModal';

// Services
import { deleteClient, getClientPaginated } from '../../services/client';

// Libraries
import moment from 'moment';

const ClientList = () => {
  const cacheName = 'client';
  const {
    resetFilters,
    getDataWithFilters,
    valuesFilters,
    setValuesFilters,
    deleteData,
    showFilters,
    toggleFilters,
    handleDeleteItem,
    handleEditItem,
    handleCloseDeleteItem,
    ...tableControlProps
  } = useTableControl({
    getAllAnyWithFilters: getClientPaginated,
    orderBy: 'Order',
    cacheName,
  });
  const { navigate } = useRouter();

  const renderDate = (date) => {
    if (!date || date === null) {
      return 'N/A';
    }
    return moment(date).format('DD/MM/YYYY');
  };

  const renderAvatar = (avatar) => {
    return <Avatar src={avatar} variant="square" />;
  };

  const columns = [
    { id: 'LogoUrl', type: 'custom', render: renderAvatar, label: 'Logo', isSortable: false },
    { id: 'Name', type: 'string', label: 'Name', isSortable: true },
    { id: 'Order', type: 'string', label: 'Order', isSortable: true },
    { id: 'CreatedBy', type: 'string', label: 'Created By', isSortable: true },
    { id: 'UpdatedBy', type: 'string', label: 'Updated By', isSortable: true },
    { id: 'CreatedAt', type: 'custom', render: renderDate, label: 'Created At', isSortable: true },
    { id: 'UpdatedAt', type: 'custom', render: renderDate, label: 'Updated At', isSortable: true },
    { id: 'actions', type: 'actions' },
  ];

  const actions = [
    { type: 'edit', func: handleEditItem },
    { type: 'delete', func: handleDeleteItem, paramNeeded: 'all' },
  ];

  const filters = [
    { title: 'Name', name: 'Name', type: 'string', evaluator: 'lk', containerName: 'default' },
    { title: 'Order', name: 'Order', type: 'number', evaluator: 'lk', containerName: 'default' },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Title text1="Client" text2="List" />
      </Grid>
      <Grid item xs={12} className="mb-5">
        <Button variant="contained" color="secondary" className="mr-3" size="small" onClick={() => navigate('add')}>
          Add new item
        </Button>
        <Button variant="outlined" color="primary" onClick={toggleFilters} size="small">
          {showFilters ? 'Hide' : 'Show'} filters
        </Button>
      </Grid>
      {showFilters && (
        <Grid item xs={12} md={3} className="md:pr-5 lg:mb-0 mb-5">
          <Filters
            filters={filters}
            resetFilters={resetFilters}
            sendFilters={getDataWithFilters}
            values={valuesFilters}
            setValues={setValuesFilters}
          />
        </Grid>
      )}
      <Grid item xs={12} md={showFilters ? 9 : 12}>
        <TableComponent {...tableControlProps} columns={columns} actions={actions} />
        <DeleteModal
          {...deleteData}
          handleCloseDeleteItem={handleCloseDeleteItem}
          deleteFunc={deleteClient}
          resetFilters={resetFilters}
          cacheName={cacheName}
        />
      </Grid>
    </Grid>
  );
};

export default ClientList;
