import _ from 'underscore';
import axiosInstance from '../config/axiosInstance';
import errorHandler from '../utils/errorHandler';

export const getCareersPaginated = async ({ filters, continuationToken, order, rowsPerPage }) => {
  try {
    const headers = { 'x-page-size': rowsPerPage, 'x-is-paginated': true };

    if (filters && !_.isEmpty(filters)) {
      headers['x-filters'] = JSON.stringify(filters);
    }

    if (continuationToken) {
      headers['x-continuation-token'] = continuationToken;
    }

    if (order && order.by) {
      headers['x-order'] = `${order.by} ${order.type}`;
    }

    const { data } = await axiosInstance().get('/career', { headers });
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const getCareersById = async (id) => {
  try {
    const { data } = await axiosInstance().get(`/career/${id}`);
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const createCareers = async (values) => {
  try {
    const { data } = await axiosInstance().post(`/career`, values);
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const updateCareers = async (values, id) => {
  try {
    const { data } = await axiosInstance().put(`/career/${id}`, values);
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const deleteCareers = async (id) => {
  try {
    const { data } = await axiosInstance().delete(`/career/${id}`);
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};
