// Components
import { Grid } from '@mui/material';
import Title from '../../components/shared/Title';
import FormComponent from '../../components/shared/FormComponent';

// Services
import { createMetrics, getMetricsById, updateMetrics } from '../../services/metrics';

const MetricsForm = () => {
  const fields = [
    { name: 'Title', label: 'Title', type: 'string', required: true },
    { name: 'Description', label: 'Description', type: 'text', required: true },
    { name: 'Value', label: 'Value', type: 'string', required: true, helperText: 'Separated by comma, Example: 2,-,4,Weeks' },
    { name: 'Order', label: 'Order', type: 'number', required: true },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Title text1="Metrics" text2="Form" />
      </Grid>
      <Grid item xs={12}>
        <FormComponent fields={fields} getById={getMetricsById} createElement={createMetrics} updateElement={updateMetrics} cacheName="metrics" />
      </Grid>
    </Grid>
  );
};

export default MetricsForm;
