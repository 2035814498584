// Hooks
import { memo, useCallback } from 'react';

// Components
import { Box, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';

// Utils
import { visuallyHidden } from '@mui/utils';

const EnhancedTableHead = memo(({ order, onRequestSort, columns }) => {
  const createSortHandler = useCallback(
    (property) => (event) => {
      onRequestSort(event, property);
    },
    [onRequestSort]
  );

  if (!columns) return null;

  const classes = 'bg-blue-700 text-white-50 first:rounded-tl-md last:rounded-tr-md whitespace-nowrap';

  return (
    <TableHead>
      <TableRow>
        {columns.map((column) =>
          column.isSortable ? (
            <TableCell
              key={column.id}
              align={column.type === 'number' ? 'right' : 'left'}
              padding={column.disablePadding ? 'none' : 'normal'}
              sortDirection={order.by === column.id ? order.type : false}
              className={classes}
            >
              <TableSortLabel
                active={order.by === column.id}
                direction={order.by === column.id ? order.type : 'asc'}
                onClick={createSortHandler(column.id)}
                className={`hover:text-white-200 ${order.by === column.id ? 'text-white-50' : ''}`}
              >
                {column.label}
                {order.by === column.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order.type === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell
              key={column.id}
              align={column.type === 'number' ? 'right' : 'left'}
              padding={column.disablePadding ? 'none' : 'normal'}
              className={classes}
            >
              {column.label}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
});

export default EnhancedTableHead;
