import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useAuthStore from '../../store/useAuthStore';

const PublicRoute = ({ children }) => {
  const { user, token } = useAuthStore();
  let location = useLocation();

  if (user && token) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};

export default PublicRoute;
