// Components
import { Grid } from '@mui/material';
import Title from '../../components/shared/Title';
import FormComponent from '../../components/shared/FormComponent';

// Services
import { createService, getServiceById, updateService } from '../../services/service';

const ServiceForm = () => {
  const fields = [
    { name: 'Logo', label: 'Logo', type: 'img', required: true, preview: 'LogoUrl' },
    { name: 'Image', label: 'Image', type: 'img', required: true, preview: 'ImageUrl' },
    { name: 'Title', label: 'Title', type: 'string', required: true },
    { name: 'Description', label: 'Description', type: 'text', required: true },
    { name: 'ListSection', label: 'ListSection', type: 'string', required: false, helperText: 'Separated by comma' },
    { name: 'Order', label: 'Order', type: 'number', required: true },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Title text1="Service" text2="Form" />
      </Grid>
      <Grid item xs={12}>
        <FormComponent fields={fields} getById={getServiceById} createElement={createService} updateElement={updateService} cacheName="service" />
      </Grid>
    </Grid>
  );
};

export default ServiceForm;
