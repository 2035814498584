import { useQuery } from '@tanstack/react-query';
import { getAllUsers } from '../services/user';

const useGetAllUsers = () => {
  const query = useQuery(['users', 'filters'], () => getAllUsers({ order: { by: 'Name', type: 'asc' } }), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return query;
};

export default useGetAllUsers;
