// Hooks
import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import useGeneralStore from '../../store/useGeneralStore';

// Components
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// Utils
import { forwardRef } from 'react';
import { propertyAccessor } from '../../utils/general';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteModal = ({ open, element = {}, handleCloseDeleteItem, deleteFunc, keyTitle = 'Title', resetFilters, cacheName }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { setSnackbarData, resetSnackbarData } = useGeneralStore();
  const queryClient = useQueryClient();

  const handleDelete = async () => {
    setIsLoading(true);
    resetSnackbarData();
    const result = await deleteFunc(element.Id);
    if (result && result.Id) {
      await resetFilters();
      queryClient.removeQueries({
        queryKey: [cacheName],
      });
      setSnackbarData({ open: true, message: 'Item deleted successfully', severity: 'success' });
    } else {
      setSnackbarData({ open: true, message: result.message, severity: 'error' });
    }
    handleCloseDeleteItem();
    setIsLoading(false);
  };

  if (!element) {
    return null;
  }

  const title = propertyAccessor(element, keyTitle);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={!isLoading ? handleCloseDeleteItem : undefined}
      aria-describedby="alert-dialog-delete-element"
    >
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Do you really want to delete <b className="text-blue-900">{title}</b>? This process can not be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDeleteItem} variant="outlined" disabled={isLoading}>
          Cancel
        </Button>
        <LoadingButton onClick={handleDelete} color="error" variant="contained" loading={isLoading}>
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
