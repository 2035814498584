// Hooks
import useGetAllUsers from '../../hooks/useGetAllUsers';

// Components
import { Grid } from '@mui/material';
import Title from '../../components/shared/Title';
import FormComponent from '../../components/shared/FormComponent';

// Services
import { createTestimonials, getTestimonialsById, updateTestimonials } from '../../services/testimonials';

const TestimonialsForm = () => {
  const { data } = useGetAllUsers();

  const fields = [
    {
      name: 'UserId',
      label: 'User',
      type: 'autocomplete',
      required: true,
      data: {
        options: data && data.data ? data.data : [],
        isObject: true,
        keyLabel: 'Name',
        keyValue: 'Id',
      },
    },
    { name: 'Description', label: 'Description', type: 'text', required: true, rows: 7 },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Title text1="Testimonials" text2="Form" />
      </Grid>
      <Grid item xs={12}>
        <FormComponent
          fields={fields}
          getById={getTestimonialsById}
          createElement={createTestimonials}
          updateElement={updateTestimonials}
          cacheName="testimonials"
        />
      </Grid>
    </Grid>
  );
};

export default TestimonialsForm;
