import _ from 'underscore';
import axiosInstance from '../config/axiosInstance';
import errorHandler from '../utils/errorHandler';

export const getMetricsPaginated = async ({ filters, continuationToken, order, rowsPerPage }) => {
  try {
    const headers = { 'x-page-size': rowsPerPage, 'x-is-paginated': true };

    if (filters && !_.isEmpty(filters)) {
      headers['x-filters'] = JSON.stringify(filters);
    }

    if (continuationToken) {
      headers['x-continuation-token'] = continuationToken;
    }

    if (order && order.by) {
      headers['x-order'] = `${order.by} ${order.type}`;
    }

    const { data } = await axiosInstance().get('/metric', { headers });
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const getMetricsById = async (id) => {
  try {
    const { data } = await axiosInstance().get(`/metric/${id}`);
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const createMetrics = async (values) => {
  try {
    const { data } = await axiosInstance().post(`/metric`, values);
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const updateMetrics = async (values, id) => {
  try {
    const { data } = await axiosInstance().put(`/metric/${id}`, values);
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const deleteMetrics = async (id) => {
  try {
    const { data } = await axiosInstance().delete(`/metric/${id}`);
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};
