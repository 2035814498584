// Components
import { Grid } from '@mui/material';
import Title from '../../components/shared/Title';
import FormComponent from '../../components/shared/FormComponent';

// Services
import { createUsers, getUsersById, updateUsers } from '../../services/user';

const UsersForm = () => {
  const fields = [
    { name: 'Name', label: 'Name', type: 'string', required: true },
    { name: 'Email', label: 'Email', type: 'string', required: true },
    { name: 'Password', label: 'Password', type: 'password', required: true, helperText: 'Must have at least 6 characters' },
    { name: 'Position', label: 'Position', type: 'string', required: true },
    { name: 'LinkedIn', label: 'LinkedIn', type: 'string', required: true },
    { name: 'Photo', label: 'Photo', type: 'img', required: true, preview: 'PhotoUrl' },
    {
      name: 'TypePosition',
      label: 'TypePosition',
      type: 'select',
      required: true,
      data: {
        options: [
          { label: 'Sherpa', value: 'sherpa' },
          { label: 'Administrative', value: 'administrative' },
          { label: 'Advisor', value: 'advisor' },
        ],
        isObject: true,
        keyLabel: 'label',
        keyValue: 'value',
      },
    },
    { name: 'Phone', label: 'Phone', type: 'string', required: false },
    { name: 'Country', label: 'Country', type: 'string', required: false },
    {
      name: 'Role',
      label: 'Role',
      type: 'select',
      required: true,
      data: {
        options: [
          { label: 'User', value: 'user' },
          { label: 'Editor', value: 'editor' },
          { label: 'Admin', value: 'admin' },
        ],
        isObject: true,
        keyLabel: 'label',
        keyValue: 'value',
      },
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Title text1="Users" text2="Form" />
      </Grid>
      <Grid item xs={12}>
        <FormComponent fields={fields} getById={getUsersById} createElement={createUsers} updateElement={updateUsers} cacheName="users" />
      </Grid>
    </Grid>
  );
};

export default UsersForm;
