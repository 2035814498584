// Hooks
import { useEffect, useState } from 'react';
import useRouter from '../../hooks/useRouter';
import useGeneralStore from '../../store/useGeneralStore';

// Components
import { LoadingButton } from '@mui/lab';
import { Box, FormGroup, Grid, TextField, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Title from '../../components/shared/Title';

// Services
import { resetPassword } from '../../services/user';

// Assets
import logo from '../../assets/img/full-logo.png';

// Libraries
import moment from 'moment/moment';
import { isExpired, decodeToken } from 'react-jwt';

const ResetPassword = () => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { setSnackbarData, resetSnackbarData } = useGeneralStore();
  const { query, navigate } = useRouter();

  useEffect(() => {
    getDataToken();
    // eslint-disable-next-line
  }, []);

  const getDataToken = () => {
    const myDecodedToken = decodeToken(query.key);
    const isMyTokenExpired = isExpired(query.key);
    if (!myDecodedToken || isMyTokenExpired) {
      setIsError(true);
      setSnackbarData({ open: true, message: 'Link to change password is invalid or has expired', severity: 'error' });
    } else {
      setData((prev) => ({ ...prev, Email: myDecodedToken.Email }));
    }
  };

  const onChangeData = ({ target: { value, name } }) => {
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    resetSnackbarData();
    if (data.Password !== data.PasswordConfirmation || (data.Password && data.Password.length < 6) || isError) {
      return null;
    }

    const result = await resetPassword(data);
    if (result.error) {
      setSnackbarData({ open: true, message: result.message, severity: 'error' });
    } else {
      setSnackbarData({ open: true, message: 'Your password has been changed successfully', severity: 'success' });
      navigate('/login');
    }
    setIsLoading(false);
  };

  return (
    <Grid container spacing={4} alignItems="center">
      <Grid item xs={12} md={7}>
        <Box sx={{ backgroundColor: 'secondary.main' }} className="md:h-screen h-28 flex items-center justify-center">
          <img src={logo} alt="logo" className="w-9/12" />
        </Box>
      </Grid>
      <Grid item xs={12} md={5}>
        <form className="lg:p-32 p-10" onSubmit={handleLogin}>
          <Title text1="Reset" text2="Password" />
          <FormGroup className="mb-5">
            <TextField
              label="Password"
              variant="outlined"
              required
              value={data.Password || ''}
              onChange={onChangeData}
              name="Password"
              type="password"
              helperText="Must have at least 6 characters"
              disabled={isError}
            />
          </FormGroup>
          <FormGroup className="mb-5">
            <TextField
              label="Password Confirmation"
              variant="outlined"
              required
              value={data.PasswordConfirmation || ''}
              onChange={onChangeData}
              name="PasswordConfirmation"
              type="password"
              disabled={isError}
            />
          </FormGroup>
          <div className="flex items-center justify-between">
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isLoading}
              disabled={data.Password !== data.PasswordConfirmation || (data.Password && data.Password.length < 6) || isError}
            >
              Send
            </LoadingButton>

            <Link to="/login" className="hover:underline hover:text-blue-700 text-blue-900 inline-block">
              Back to login
            </Link>
          </div>
        </form>
        <Typography className="text-center">© {moment().year()} All Rights Reserved</Typography>
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
