// Hooks
import { useEffect, useRef, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useRouter from '../../hooks/useRouter';

// Components
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Switch,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Autocomplete,
} from '@mui/material';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import Loading from './Loading';

// Store
import useAuthStore from '../../store/useAuthStore';
import useGeneralStore from '../../store/useGeneralStore';

// Libraries
import draftjsToHtml from 'draftjs-to-html';
import htmlToDraftjs from 'html-to-draftjs';

// Assets
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { validateNumber } from '../../utils/general';
import ImgField from './ImgField';

const FormComponent = ({ cacheName, fields, getById = () => {}, createElement, updateElement }) => {
  const { navigate, query, match } = useRouter();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [values, setValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const { setSnackbarData } = useGeneralStore();
  const { user, token, setAuthData } = useAuthStore();
  const queryClient = useQueryClient();

  const isProfilePage = match('/profile') ? true : false;
  const fieldTypeEditor = useRef(null);
  const fieldTypePassword = useRef(null);

  const queryForm = useQuery([cacheName, query.id || user.Id], () => getById(query.id || user.Id), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: 1000 * 60 * 60,
    enabled: Boolean(query.id),
  });

  useEffect(() => {
    getValueEditorIfExists();
    getValuePasswordIfExists();

    if (isProfilePage) {
      setValues({ ...user });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (queryForm.data) {
      const result = queryForm.data;
      if (fieldTypeEditor.current) {
        convertValue(result[fieldTypeEditor.current.name]);
      }
      setValues(result);
    }
  }, [queryForm.data]);

  const convertValue = (desc) => {
    const blocksFromHtml = htmlToDraftjs(desc);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const newEditorState = EditorState.createWithContent(contentState);
    setEditorState(newEditorState);
  };

  const getValueEditorIfExists = () => {
    const field = fields.find((field) => field.type === 'editor');
    if (field) {
      fieldTypeEditor.current = field;
    }
  };

  const getValuePasswordIfExists = () => {
    const field = fields.find((field) => field.type === 'password');
    if (field) {
      fieldTypePassword.current = field;
    }
  };

  const onChangeString = ({ target: { value, name } }) => {
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  const onChangeNumber = ({ target: { value, name } }) => {
    if (validateNumber(value)) setValues((prev) => ({ ...prev, [name]: value }));
  };

  const onChangeBoolean = ({ target: { checked, name } }) => {
    setValues((prev) => ({ ...prev, [name]: checked }));
  };

  const onChangeSelect = ({ target: { value, name } }) => {
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  const onChangeAutocomplete = (newValue, name, keyValue) => {
    setValues((prev) => ({ ...prev, [name]: keyValue ? (newValue ? newValue[keyValue] : '') : newValue }));
  };

  const onChangeImg = ({ target: { files, name } }) => {
    const preview = URL.createObjectURL(files[0]);
    setValues((prev) => ({ ...prev, [name]: files[0], [`${name}_preview`]: preview }));
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrors({});
    const data = Object.assign({}, values);
    if (fieldTypeEditor.current) {
      const newFieldEditor = draftjsToHtml(convertToRaw(editorState.getCurrentContent()));
      data[fieldTypeEditor.current.name] = newFieldEditor;
    }

    let result = null;
    if (query.id || isProfilePage) {
      result = await updateElement(data, query.id || user.Id);
    } else {
      result = await createElement(data);
    }

    if (result && result.Id) {
      setSnackbarData({ open: true, message: 'Record saved successfully', severity: 'success' });
      setIsLoading(false);
      queryClient.removeQueries({
        queryKey: [cacheName],
      });
      if (isProfilePage) {
        setAuthData({ user: result, token });
      } else {
        handleGoBack();
      }
    } else {
      setSnackbarData({ open: true, message: result.message, severity: 'error' });
      setErrors(result.errorsList);
      setIsLoading(false);
    }
  };

  const renderField = ({ name, label, type, required = false, preview, maxLength, helperText, data, disabled, rows = 3 }) => {
    switch (type) {
      case 'editor':
        return (
          <>
            <Typography className="text-gray-500">{label}</Typography>
            <Editor
              editorState={editorState}
              wrapperClassName="border rounded"
              toolbarClassName="border-0 border-b"
              onEditorStateChange={setEditorState}
            />
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
            <FormHelperText className="text-danger-600">{errors[name]}</FormHelperText>
          </>
        );
      case 'boolean':
        return (
          <>
            <FormControlLabel
              disabled={disabled}
              control={<Switch checked={values[name] || false} />}
              label={label}
              onChange={onChangeBoolean}
              name={name}
            />
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
          </>
        );
      case 'select': {
        return (
          <>
            <FormControl fullWidth disabled={disabled}>
              <InputLabel id={`select_${name}`}>{label}</InputLabel>
              {data && data.options && data.options.length > 0 && (
                <Select labelId={`select_${name}`} value={values[name] || ''} name={name} label={label} onChange={onChangeSelect}>
                  {data.options.map((option) => (
                    <MenuItem value={data.isObject ? option[data.keyValue] : option} key={data.isObject ? option[data.keyValue] : option}>
                      {data.isObject ? option[data.keyLabel] : option}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
          </>
        );
      }
      case 'autocomplete': {
        const { options, isObject, keyValue, keyLabel } = data;

        let value = '';
        if (isObject) {
          value = options.find((op) => op[keyValue] === values[name]) || null;
        } else {
          value = values[name];
        }

        return (
          <Autocomplete
            id={`autocomplete_${name}`}
            options={options}
            // groupBy={(option) => option.firstLetter}
            renderOption={(props, option) => (
              <li {...props} key={isObject ? option[keyValue] : option}>
                {isObject ? option[keyLabel] : option}
              </li>
            )}
            getOptionLabel={(option) => {
              if (option) {
                return isObject ? option[keyLabel] : option;
              }
              return '';
            }}
            renderInput={(params) => <TextField {...params} label={label} />}
            noOptionsText="Empty list"
            value={value}
            onChange={(e, value) => onChangeAutocomplete(value, name, keyValue)}
          />
        );
      }
      case 'img': {
        return (
          <ImgField
            label={label}
            values={values}
            preview={preview}
            name={name}
            onChangeImg={onChangeImg}
            disabled={disabled}
            helperText={helperText}
            errors={errors}
            setValues={setValues}
          />
        );
      }
      case 'number':
        return (
          <>
            <TextField
              label={label}
              variant="outlined"
              name={name}
              value={values[name] || ''}
              onChange={onChangeNumber}
              required={required}
              helperText={helperText}
              error={Boolean(errors[name])}
              disabled={disabled}
            />
            {errors[name] && <FormHelperText className="text-danger-600">{errors[name]}</FormHelperText>}
          </>
        );
      default: {
        const isPassword = type === 'password';
        const value = isPassword && query.id ? (values[name] || values[name] === '' ? values[name] : 'inputpassword') : values[name] || '';

        return (
          <>
            <TextField
              label={label}
              variant="outlined"
              name={name}
              type={isPassword ? 'password' : 'text'}
              value={value}
              onChange={onChangeString}
              required={required}
              multiline={type === 'text'}
              rows={type === 'text' ? rows : undefined}
              inputProps={{ maxLength }}
              helperText={maxLength ? `The maximum number of characters is ${maxLength}` : undefined}
              error={Boolean(errors[name])}
              disabled={disabled}
            />
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
            {errors[name] && <FormHelperText className="text-danger-600">{errors[name]}</FormHelperText>}
          </>
        );
      }
    }
  };

  if (isLoading || queryForm.isFetching) return <Loading />;

  return (
    <form onSubmit={handleSubmit}>
      {fields.map((field) => (
        <FormGroup className="mb-5" key={field.name}>
          {renderField(field)}
        </FormGroup>
      ))}
      <Box className="text-right mb-3">
        <Button variant="outlined" color="secondary" onClick={handleGoBack} className="mr-3">
          Back
        </Button>
        <Button variant="contained" type="submit">
          Save
        </Button>
      </Box>
    </form>
  );
};

export default FormComponent;
