// Components
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';

// Hooks
import useRouter from '../../hooks/useRouter';
import useAuthStore from '../../store/useAuthStore';

const ListItemDrawer = ({ path, name, Icon, inSidebar, permissionType }) => {
  const { match } = useRouter();
  const { user } = useAuthStore();

  const classes = `hover:bg-[rgba(255,255,255,0.08)] ${match(path) ? 'bg-[rgba(255,255,255,0.16)]' : ''}`;

  if (!inSidebar) {
    return null;
  }

  if ((permissionType === 'admin' || permissionType === 'editor') && user.Role === 'user') {
    return null;
  }

  if (permissionType === 'admin' && user.Role === 'editor') {
    return null;
  }

  return (
    <Link to={path}>
      <ListItem disablePadding>
        <ListItemButton className={classes}>
          <ListItemIcon className="text-white-50">
            <Icon />
          </ListItemIcon>
          <ListItemText primary={name} />
        </ListItemButton>
      </ListItem>
    </Link>
  );
};

export default ListItemDrawer;
